<template>

<router-link :to="{name: route}" class="item">

	<app-icon :icon="icon" class="item-icon" />

	<div class="item-text">{{ text }}</div>

</router-link>

</template>

<script>

export default {

	props: ['route', 'icon', 'text']

}

</script>

<style scoped>

.item {
	width: 64px;
	cursor: pointer;
	padding: 8px 0px;
	flex-shrink: 0;
	transition: all 100ms linear;
}

.item.router-link-active {
	background-color: #4082d3;
}

.item:not(.router-link-active):hover {
	background-color: #11316e;
}

.item-icon {
	color: #fff;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
}

.item-text {
	font-size: 12px;
	color: #fff;
	font-weight: 300;
	margin-top: 4px;
	text-align: center;
}

.item.router-link-active .item-icon,
.item.router-link-active .item-text {
	color: #fff;
}

</style>
